<template>
    <div class="all">
        <Home2Header active-index="/about"></Home2Header>
        <AboutHeader2 active-index2="/contact"></AboutHeader2>
        <div class="main">
            <div class="amap-wrapper" style="height: 35vw;min-height:380px;margin: 0 0 30px 0;border: 1px solid #e5e5e5;border-radius: 4px;">
                <el-amap vid="amapDemo" :center="center" :zoom="zoom">
                    <el-amap-marker vid="marker" :position="center" :label="label"/>
                </el-amap>
            </div>

          <el-row :gutter="30" style="margin-top: 20px;">
            <el-col :span="8">
              <el-card shadow="never"
                       style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
                <el-image :src="require('../../assets/About/1.png')" lazy style="height:5vw;width:3.36vw;min-width: 32px;min-height:48px;"/>
                <p>公司地址<br>广州市黄埔区光谱中路11号云升科学园B1栋2A层2A04</p>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="never"
                       style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
                <el-image :src="require('../../assets/About/2.png')" lazy style="height: 3.9vw;width: 5vw;min-width: 48px;min-height: 37px;"/>
                <p>邮箱地址<br>gaoxd@toodo.com.cn</p>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="never"
                       style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
                <el-image :src="require('../../assets/About/3.png')" lazy style="width:3.95vw;height: 5vw;min-width: 38px;min-height: 48px;"/>
                <p>服务热线<br>4001-600-891</p>
              </el-card>
            </el-col>
          </el-row>

          <el-row :gutter="30" style="margin-top: 20px;">
            <el-col :span="8">
              <el-card shadow="never"
                       style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
                <el-image :src="require('../../assets/About/4.png')" lazy style="height:5vw;width:4.2vw;min-width: 40px;min-height: 48px"/>
                <p>公交车<br>站台：揽月路西、光谱中路</p>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="never"
                       style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
                <el-image :src="require('../../assets/About/5.png')" lazy style="height:3.6vw;width:5vw;min-width: 48px;min-height: 34px;"/>
                <p>出租车/自驾</p>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="never"
                       style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
                <el-image :src="require('../../assets/About/6.png')" lazy style="height:5vw;width:4.8vw;min-width: 46px;min-height: 48px"/>
                <p>地铁<br>站台：科学城</p>
              </el-card>
            </el-col>
          </el-row>

        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Home2Header from "../../components/Home2Header";
    import AboutHeader2 from "../../components/AboutHeader2";
    import Footer from "../../components/Footer";

    export default {
        name: "Contact",
        data() {
            return {
                zoom: 15,
                center: [113.445334, 23.160048],
                label: {
                    content: '广州双动科技有限公司<br>广州市黄埔区光谱中路11号云升科学园B1栋2A层2A04',
                    offset: [25, -15]
                },
            }
        },
        methods: {},
        components: {
            Home2Header, AboutHeader2, Footer
        },
        props: {
            activeIndex: {
                type: String
            },
            activeIndex2: {
                type: String
            }
        },
    }
</script>

<style scoped>
    .all{
        min-width:960px;
    }
    .main {
        margin: 50px 10% 50px 10%;
    }
</style>
